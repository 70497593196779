import React from 'react';
import { Route, Switch, Redirect, useParams, } from 'react-router-dom';
import _ from 'lodash';
import loadable from '@loadable/component';
import withBFCAuth from './withBFCAuth';

const Layout = loadable(() => import('../Layout/index'));
const MainWrapper = loadable(() => import('./MainWrapper'));

import Account from '../Account';
import PlanList from 'pages/storage/components/PlanList';
import EquipmentRental from 'pages/CTEquipmentRental';
import EquipmentProfile from 'pages/CTEquipmentRental/equipmentProfile';
import IndentDeals from 'pages/CTIndentDeals';

import QuickTransfer from 'pages/storage/components/QuickTransfer';
import StorageTransfer from 'pages/storage/components/StorageTransfers';
import EquipmentPayments from 'pages/CTEquipmentRental/equipmentPayments';
import { getDecryptedUrl } from 'lib/utils';
//import BidOffer from 'pages/bidOffer';

// import DataSetup from 'pages/CTDataSetup';

const Login = loadable(() => import('../Account/Login'));
const NotFound404 = loadable(() => import('../Misc/DefaultPage/404/index'));
const UnderConstruction = loadable(() => import('../Misc/UnderConstruction/index'));
const Signup = loadable(() => import('../Account/Signup'));
const Social = loadable(() => import('pages/socialFeed'));
const Html = loadable(() => import('containers/Html'));
const UserDashboard = loadable(() => import('pages/dashboard'));
const ControlTowerDashboard = loadable(() => import('pages/dashboard/controlTower'));
const UserNetwork = loadable(() => import('pages/network'));
const UserSupport = loadable(() => import('pages/support'));
const CommodityListPage = loadable(() => import('pages/commodity/list'));
const CommodityDetailsPage = loadable(() => import('pages/commodityDetails'));
const CommodityPlansDetailsPage = loadable(() => import('pages/commodityPlansDetails'));
const MultipartyAuctionListPage = loadable(() => import('pages/multipartyAuction/list'));
const CommodityAuctionDetailsPage = loadable(() => import('pages/commodityAuctionDetails'));
const CommodityCreatePage = loadable(() => import('pages/commodity/create'));
const CommodityCreatePlans = loadable(() => import('pages/commodity/createPlan'));
const CommodityCreateAuction = loadable(() => import('pages/commodity/createAuction'));
const KYCApproval = loadable(() => import('../Account/Profile/KYCApproval'));
const CommodityEditPage = loadable(() => import('pages/commodity/edit'));
const CommodityEditPlan = loadable(() => import('pages/commodity/editPlan'));
const CommodityEditAuction = loadable(() => import('pages/commodity/editAuction'));
const AuctionLiveRoom = loadable(() => import('pages/commodityAuctionDetails/auctionLiveRoom'));
const AuctionLiveRoomDeals = loadable(() => import('pages/commodityAuctionDetails/auctionDeals'));
const DeliveryDetails = loadable(() => import('pages/deliveryDetails'));
const TransportRequirementPage = loadable(() => import('pages/transport/list'));
const CommodityDealDetailsPage = loadable(() => import('pages/commodityDealDetails'));
const CommodityDealPage = loadable(() => import('pages/commodityDetails/deals'));
const TransportOverviewPage = loadable(() => import('pages/transportDetails/overview'));
const TransportBalancePositionPage = loadable(() => import('pages/transportDetails/balancePosition'));
const TransportBidsPage = loadable(() => import('pages/transportDetails/bids'));
const TransportDealPage = loadable(() => import('pages/transportDetails/deals'));
const TransportDealOverviewPage = loadable(() => import('pages/transportDealDetails/overview'));
const TRDealInvoiceListPage = loadable(() => import('pages/transportDealDetails/invoice'));
const TRDealPaymentListPage = loadable(() => import('pages/transportDealDetails/payment/list'));
const OngoingDeliveries = loadable(() => import('pages/delivery/components/onGoingDeliveries'));
const InvoiceListPage = loadable(() => import('pages/invoice/list'));
const PaymentListPage = loadable(() => import('pages/payment/list'));
const OverviewPage = loadable(() => import('pages/overview'));
const PaymentDetails = loadable(() => import('pages/payment/details'));
const PaymentDetailsOverview = loadable(() => import('pages/payment/overview'));
const InvoiceForPayments = loadable(() => import('pages/payment/overview/InvoiceForPayments'));
const InvoiceDetails = loadable(() => import('pages/invoice/details'));
const CombinedInvoice = loadable(() => import('pages/cashflow/combinedInvoice'));
const RecordPaymentForm = loadable(() => import('pages/invoice/recordPayments'));
const GenerateReport = loadable(() => import('pages/generateReport'));
const BidOffer = loadable(() => import('pages/bidOffer'));
const BuySellTracker = loadable(() => import('pages/CTDataSetup/buySellTracker'));
const CreateDiscountPlan = loadable(() => import('pages/discountPlan/create'));
const EditDiscountPlan = loadable(() => import('pages/discountPlan/edit'));
const FetchDiscountPlan = loadable(() => import('pages/discountPlan/list'));
const DiscountDetails = loadable(() => import('pages/discountPlanDetails'));
const CTSettings = loadable(() => import('pages/CTSettings/index'));
const DeliveryContractForm = loadable(() => import('pages/CTDeliveryContract/create'));
const DeliveryContractEditForm = loadable(() => import('pages/CTDeliveryContract/edit'));
const TransportDealDetails = loadable(() => import('pages/network/transportDeal/transportDealDetails'));
const LinkTransportForm = loadable(() => import('pages/CTDeliveryContract/linkTransport'));
const DeliveryContractDetails = loadable(() => import('pages/CTDeliveryContract/components/DeliveryContractDetails'));
const DriverList = loadable(() => import('pages/network/driver/index'));
const VehicleList = loadable(() => import('pages/network/vehicle/index'));
const SubscriptionPackages = loadable(() => import('pages/subscription/SubscriptionPackages'));
const Subscription = loadable(() => import('pages/subscription/Subscriptions'));
const SubscriptionDetails = loadable(() => import('pages/subscription/components/SubscriptionDetails'));
const Storage = loadable(() => import('pages/storage'));
const StorageBinDetailsPage = loadable(() => import('pages/storage/components/binDetails'));
const StorageTable = loadable(() => import('pages/storage/components/storageTable'));
const Configuration = loadable(() => import('pages/configuration'));
const AuthorizedSignatory = loadable(() => import('pages/configuration/authorizedSignatory'));
const LoadingUnloading = loadable(() => import('pages/configuration/loadingUnloading'));
const TransportDeal = loadable(() => import('pages/network/transportDeal'));
const CommodityDetailsDataSetup = loadable(() => import('pages/CTDataSetup/commodityDetails'));
const PlatformChargesDataSetup = loadable(() => import('pages/CTDataSetup/platformCharges'));
const DataExtract = loadable(() => import('pages/CTDataSetup/dataExtract'));
const DataExtractNew = loadable(() => import('pages/CTDataSetup/dataExtractNew'));
const ReportPlatformCharges = loadable(() => import('pages/CTDataSetup/reportPlatformCharges'))
const EmissionsSaved = loadable(() => import('pages/CTDataSetup/sustainability'))
const QualityServicesList = loadable(() => import('pages/network/qualityServices/list'));
const QualityServicePage = loadable(() => import('pages/network/qualityServices/create'));
const QualityServiceEditPage = loadable(() => import('pages/network/qualityServices/edit'));
const QualityProfilePage = loadable(() => import('pages/QualityProfile'));
const QualityServiceOverview = loadable(() => import('pages/network/qualityServices/components/details'));
const QualityServiceDocument = loadable(() =>
  import('pages/network/qualityServices/components/details/uploadDocuments'),
);
const Finance = loadable(() => import('pages/finance'));
const FinanceDetails = loadable(() => import('pages/finance/components/FinanceDetails'));
const FinanceUserDetails = loadable(() => import('pages/finance/components/FinanceUserDetails'));
const FinanceDeals = loadable(() => import('pages/finance/components/financeDeals'));
const FinanceInvoices = loadable(() => import('pages/finance/components/financeInvoices'));
const FinanceTable = loadable(() => import('pages/finance/components/financeTable'));
const BankNbfcForm = loadable(() => import('pages/finance/components/create'));
const MarketFinanceInvoices = loadable(() => import('pages/finance/components/MarketFinanceInvoices/list'));
const CreditLimit = loadable(() => import('pages/finance/components/creditLimit'));

const ExtendedProfileList = loadable(() => import('pages/financePartner/index'));
const ExtendedProfileSubmitForm = loadable(() => import('pages/financePartner/submitForm'));
const ExtendedProfileForm = loadable(() => import('pages/financePartner/form'));
const Subscriptions = loadable(() => import('pages/CTSubscriptions'));
const InvoiceConfiguration = loadable(() => import('pages/configuration/invoiceConfiguration'));
const ConfigurationDashBorad = loadable(() => import('pages/configuration/dashborad'));
const CashflowListPage = loadable(() => import('pages/cashflow'));
const ManualInvoice = loadable(() => import('pages/ManualInvoice'));
const ManualInvoiceListPage = loadable(() => import('pages/ManualInvoice/list'));
const FollowPreferences = loadable(() => import('pages/CTMyBioFuelCircle/FollowPreferences'));
const Banner = loadable(() => import('pages/CTMyBioFuelCircle/Banner'));
const Map = loadable(() => import('pages/CTDataSetup/MyMap'));
const ShareSocialFeed = loadable(() => import('pages/socialFeed/components/Feed/sharePost'));
const BfcFeedMainPost = loadable(() => import('pages/socialFeed/components/Feed/bfcFeedMainSite'));
const PlanPassbook = loadable(() => import('pages/subscription/components/PlanPassbook'));
const MySubscription = loadable(() => import('pages/subscription/components/MySubscription'));
const LocationSubscription = loadable(() => import('pages/subscription/components/UserSubscriptionDetails'));
const CreditUtilization = loadable(() => import('pages/finance/components/CreditUtilization'));
const Ledger = loadable(() => import('pages/ledger'));
const TransportRate = loadable(() => import('pages/network/transportRate'));
const WhatsAppLogin = loadable(() => import('containers/Account/Login/components/WhatsAppLogin'));
const PrintableQuote = loadable(() => import('pages/printableQuote/index'));
const PrintableQuoteOld = loadable(() => import('pages/printableQuote/indexOld'));


const PrivateRoute = ({ component: Component, user, location, ...rest }) => {
  if (!_.get(user, 'isAuthenticated')) {
    return (
      <Redirect
        to={{
          pathname: '/account/login',
          state: { from: location },
        }}
      />
    );
  }

  return <Route {...rest} render={(props) => <Component user={user} {...props} />} />;
};

//= ======for pages where user shouldn't visit when logged in
const AuthRoute = ({ component: Component, user, ...rest }) => {
  if (_.get(user, 'isAuthenticated')) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} render={(props) => <Component user={user} {...props} />} />;
};
const PublicRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const BFCRouter = (props) => {
  const { user } = props;
  const loc = getDecryptedUrl(window.location.href);

  let cls = 'bfc-old-style';
  const pathname = props.history.location.pathname || '';

  if (!pathname.includes('plans') && (pathname.includes('offers') || pathname.includes('requirements'))) {
    cls = 'bfc-new-style';
  }

  return (
    <MainWrapper>
      <main className={cls}>
        <div>
        {(loc.includes('mybiofuelcircle')|| loc.includes('/printable-quote')) ? null : <Layout user={user} />}

          <div className={loc.includes('/printable-quote') ? 'bfc-html' : `container__wrap ${cls === 'bfc-old-style' ? 'bfc-container__wrap bfc-html' : ''}`}>
            <Switch>
              <AuthRoute user={props.user} path="/account/login" component={Login} />
              <AuthRoute user={props.user} path="/account/signup" component={Signup} />
              <AuthRoute user={props.user} path="/sso/:id/callback" component={WhatsAppLogin} />
              <PrivateRoute user={props.user} path="/account/:id" component={Account} />
              <PrivateRoute user={props.user} path="/account" component={Account} />

              <PrivateRoute user={props.user} path="/html" component={Html} />
              <PrivateRoute user={props.user} exact path="/" component={UserDashboard} />
              <PrivateRoute user={props.user} exact path="/dashboard" component={UserDashboard} />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/:filter(all|user|bids|plans|auction-all|auction-user|auction-bids|quotations)"
                component={CommodityListPage}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/create"
                component={CommodityCreatePage}
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/plans/create"
                component={CommodityCreatePlans}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/auction/create"
                component={CommodityCreateAuction}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/edit/:id"
                component={CommodityEditPage}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/plans/edit/:id"
                component={CommodityEditPlan}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/auction/edit/:id"
                component={CommodityEditAuction}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/auction/:id/liveroom"
                component={AuctionLiveRoom}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/markets-live"
                component={MultipartyAuctionListPage}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/auction/:id/liveroom-deals"
                component={AuctionLiveRoomDeals}
              />
              <PrivateRoute
                user={props.user}
                // exact
                path="/commodity/:type(requirements|offers)/:id"
                component={CommodityDetailsPage}
              />
              <PrivateRoute
                user={props.user}
                // exact
                path="/commodity/:type(requirements|offers)/plans/:id"
                component={CommodityPlansDetailsPage}
              />
              <PrivateRoute
                user={props.user}
                // exact
                path="/commodity/:type(requirements|offers)/auction/:id"
                component={CommodityAuctionDetailsPage}
              />

              <PrivateRoute user={props.user} exact path="/commodity/deals" component={CommodityDealPage} />
              <PrivateRoute user={props.user} path="/commodity/deals/:id" component={CommodityDealDetailsPage} />
              <PrivateRoute user={props.user} exact path="/control-tower/deals" component={CommodityDealPage} />
              <PrivateRoute
                user={props.user}
                path="/control-tower/deals/:id"
                component={CommodityDealDetailsPage}
              />

              <PrivateRoute user={props.user} path="/deliveries/:id" component={DeliveryDetails} />
              <PrivateRoute user={props.user} exact path="/transport" component={TransportRequirementPage} />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport/requirements/create"
                component={CommodityCreatePage}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport/requirements/:id/overview"
                component={TransportOverviewPage}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport/requirements/:id/balance-position"
                component={TransportBalancePositionPage}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport/requirements/:id/bids"
                component={TransportBidsPage}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport/requirements/:id/deals"
                component={TransportDealPage}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport/requirements/100/deals/:id/overview"
                component={TransportDealOverviewPage}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport/requirements/100/deals/:id/tr-invoices"
                component={TRDealInvoiceListPage}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport/requirements/100/deals/:id/tr-payment"
                component={TRDealPaymentListPage}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/:place(market|network|controltower)/deliveries/:type(upcoming|ongoing|completed|missed)"
                component={OngoingDeliveries}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/:place(market|network|controltower)/cashflow"
                component={CashflowListPage}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/:place(market|network|controltower)/invoices"
                component={InvoiceListPage}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/:place(market|network|controltower)/payments"
                component={PaymentListPage}
              />

              <PrivateRoute user={props.user} exact path="/overview" component={OverviewPage} />
              <PrivateRoute user={props.user} exact path="/invoices/record-payment" component={RecordPaymentForm} />
              <PrivateRoute user={props.user} exact path="/invoices/:id" component={InvoiceDetails} />
              <PrivateRoute
                user={props.user}
                exact
                path="/invoices/:id/:manualInvoice"
                component={InvoiceDetails}
              />
              <PrivateRoute user={props.user} exact path="/combined-invoices" component={CombinedInvoice} />
              <PrivateRoute user={props.user} exact path="/payment/:id" component={PaymentDetails} />
              <PrivateRoute
                user={props.user}
                exact
                path="/payment/:id/overview"
                component={PaymentDetailsOverview}
              />
              <PrivateRoute user={props.user} exact path="/payment/:id/invoice" component={InvoiceForPayments} />
              <PrivateRoute user={props.user} exact path="/control-tower/report" component={GenerateReport} />
              <PrivateRoute user={props.user} exact path="/control-tower/bid-offer-tracker" component={BidOffer} />
              <PrivateRoute user={props.user} exact path="/control-tower/buy-sell-tracker" component={BuySellTracker} />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/create-discount"
                component={CreateDiscountPlan}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/edit-discount/:id"
                component={EditDiscountPlan}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/discount-plans"
                component={FetchDiscountPlan}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/discount/:id/schemes"
                component={DiscountDetails}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/discount/:id/claims"
                component={DiscountDetails}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/settings/change-banner"
                component={CTSettings}
              />
              <PrivateRoute user={props.user} exact path="/controltower/delivery-contract" component={TransportDeal} />
              <PrivateRoute
                user={props.user}
                exact
                path="/controltower/delivery-contract/create"
                component={DeliveryContractForm}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/controltower/delivery-contract/:id/edit"
                component={DeliveryContractEditForm}
              />
              <PrivateRoute
                user={props.user}
                path="/controltower/delivery-contract/:id"
                component={DeliveryContractDetails}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/controltower/delivery-contract/link-transport"
                component={LinkTransportForm}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/controltower/manual-invoice"
                component={ManualInvoiceListPage}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/controltower/manual-invoice/create"
                component={ManualInvoice}
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/controltower/manual-invoice/edit/:id"
                component={ManualInvoice}
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/ledger"
                component={Ledger}
              />

              <Route exact path="/commodity" render={() => <Redirect to={'/commodity/requirements/all'} />} />
              <Route exact path="/commodity/:type" render={() => <Redirect to={'/commodity/requirements/all'} />} />
              <Route
                exact
                path="/commodity/:type/:filter"
                render={() => <Redirect to={'/commodity/requirements/all'} />}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/kycapproval/:type(individual|business)"
                component={KYCApproval}
              />
              <PrivateRoute user={props.user} path="/network/driver" component={DriverList} />
              <PrivateRoute user={props.user} path="/network/vehicle" component={VehicleList} />
              <PrivateRoute exact user={props.user} path="/market/quality-services" component={QualityServicesList} />
              <PrivateRoute exact user={props.user} path="/network/quality-services" component={QualityServicesList} />
              <PrivateRoute
                exact
                user={props.user}
                path="/network/create-qualityServices"
                component={QualityServicePage}
              />
              <PrivateRoute
                exact
                user={props.user}
                path="/market/quality-services/quality-profile/:entity_id/:affilateEnum/:addressId"
                component={QualityProfilePage}
              />
              <PrivateRoute
                exact
                user={props.user}
                path="/network/quality-services/:id/edit"
                component={QualityServiceEditPage}
              />

              <PrivateRoute
                exact
                user={props.user}
                path="/market/quality-services/:id/edit"
                component={QualityServiceEditPage}
              />
              <PrivateRoute
                user={props.user}
                path="/network/quality-services/:id"
                component={QualityServiceOverview}
              />
              <PrivateRoute
                user={props.user}
                path="/market/quality-services/:id"
                component={QualityServiceOverview}
              />

              <PrivateRoute user={props.user} exact path="/network" component={UserNetwork} />
              <PrivateRoute user={props.user} exact path="/support" component={UserSupport} />
              <PrivateRoute user={props.user} exact path="/subscription-packages" component={SubscriptionPackages} />
              <PrivateRoute user={props.user} exact path="/subscription" component={Subscription} />
              <PrivateRoute
                user={props.user}
                exact
                path="/subscription/:name/:id"
                component={SubscriptionDetails}
              />
              <PrivateRoute user={props.user} path="/storages" component={Storage} />
              <PrivateRoute user={props.user} exact path="/storage/bindetails" component={StorageBinDetailsPage} />
              <PrivateRoute
                user={props.user}
                exact
                path="/storage/bindetails/:location/:level"
                component={StorageTable}
              />
              <PrivateRoute user={props.user} exact path="/storage/plan" component={PlanList} />
              <PrivateRoute user={props.user} exact path="/configuration" component={ConfigurationDashBorad} />
              <PrivateRoute
                user={props.user}
                path="/configuration/:type(preferred|blocked)"
                component={Configuration}
              />
              <PrivateRoute
                user={props.user}
                path="/configuration/authorized-signatory"
                component={AuthorizedSignatory}
              />
              <PrivateRoute
                user={props.user}
                path="/configuration/loading-unloading-charges"
                component={LoadingUnloading}
              />
              <PrivateRoute
                user={props.user}
                path="/configuration/invoice-configuration"
                component={InvoiceConfiguration}
              />
              <PrivateRoute exact user={props.user} path="/network/transport-deal" component={TransportDeal} />
              <PrivateRoute user={props.user} path="/network/transport-deal/:id">
                <TransportDealDetails backUrl="/network/transport-deal" />
              </PrivateRoute>

              <PrivateRoute
                user={props.user}
                path="/controltower/data-setup/commodity-details"
                component={CommodityDetailsDataSetup}
              />
              <PrivateRoute
                user={props.user}
                path="/controltower/data-setup/platform-charges"
                component={PlatformChargesDataSetup}
              />
              <PrivateRoute user={props.user} path="/control-tower/report/map-view" component={Map} />
              <PrivateRoute user={props.user} exact path="/extended_profile/create" component={ExtendedProfileForm} />
              <PrivateRoute
                user={props.user}
                exact
                path="/extended_profile/edit/:id"
                component={ExtendedProfileForm}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/extended_profile/submit_form/:id"
                component={ExtendedProfileSubmitForm}
              />

              <PrivateRoute user={props.user} exact path="/finance" component={Finance} />
              <PrivateRoute user={props.user} exact path="/finance-details/:id" component={FinanceDetails} />
              <PrivateRoute user={props.user} exact path="/finance/:id/overview" component={FinanceUserDetails} />
              <PrivateRoute user={props.user} exact path="/finance/:id/deals" component={FinanceDeals} />
              <PrivateRoute user={props.user} exact path="/finance/:id/invoices" component={FinanceInvoices} />
              <PrivateRoute user={props.user} exact path="/finance/invoices/all" component={FinanceInvoices} />
              <PrivateRoute user={props.user} exact path="/finance/bank-nbfc-info/create" component={BankNbfcForm} />
              <PrivateRoute user={props.user} exact path="/finance/invoices" component={MarketFinanceInvoices} />
              <PrivateRoute user={props.user} path="/finance/credit-limit" component={CreditLimit} />
              <PrivateRoute user={props.user} exact path="/finance/credit-utilization" component={CreditUtilization} />

              <PrivateRoute user={props.user} path="/controltower/data-setup/data-extract" component={DataExtract} />
              <PrivateRoute
                user={props.user}
                path="/controltower/data-setup/new-data-extract"
                component={DataExtractNew}
              />
              <PrivateRoute user={props.user} path="/controltower/data-setup/report-platform-charges" component={ReportPlatformCharges} />
              <PrivateRoute user={props.user} path="/controltower/data-setup/emission-saved" component={EmissionsSaved} />

              <PrivateRoute user={props.user} path="/extended_profile" component={ExtendedProfileList} />
              <PrivateRoute
                exact
                user={props.user}
                path="/connect/quality-services/quality-profile/:entity_id/:affilateEnum/:addressId"
                // path="/commodity/:type(requirements|offers)/:id"
                component={QualityProfilePage}
              />
              <PrivateRoute user={props.user} path="/control-tower/subscriptions" component={Subscriptions} />
              <PrivateRoute user={props.user} path="/control-tower/follow-preferences" component={FollowPreferences} />
              <PrivateRoute user={props.user} path="/follow-preferences" component={FollowPreferences} />
              <PrivateRoute user={props.user} path="/control-tower/banner" component={Banner} />

              <Route path="/under-construction">
                <UnderConstruction />
              </Route>
              <PrivateRoute
                user={props.user}
                path="/subscription/plan-passbook/:addressId/:personComapnyAffiliateId/:personComapnyAffiliateEnum"
                component={PlanPassbook}
              />
              <PrivateRoute user={props.user} path="/subscription/mySubscription" component={MySubscription} />
              <PrivateRoute
                user={props.user}
                exact
                path="/location/subscription/:addressId/:personComapnyAffiliateId/:personComapnyAffiliateEnum"
                component={LocationSubscription}
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/transport-rate"
                component={TransportRate}
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/equipment-rental"
                component={EquipmentRental}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/equipment-rental/equipment-details/:id"
                component={EquipmentProfile}
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/indent-deals"
                component={IndentDeals}
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/seller-indent-deals"
                component={IndentDeals}
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/equipment-rental/payments"
                component={EquipmentPayments}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/market/storage/transfers"
                component={StorageTransfer}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/market/storage/create-quick-transfer"
                component={QuickTransfer}
              />

              <PublicRoute
                user={props.user}
                exact
                path="/printable-quote/:id"
                component={PrintableQuote}
              />
               {/* <PublicRoute
                user={props.user}
                exact
                path="/printable-quote/:id"
                component={PrintableQuoteOld}
              /> */}
              <Route path="/">
                <NotFound404 />
              </Route>

            </Switch>
          </div>
        </div>
      </main>
    </MainWrapper>
  );
};

export default withBFCAuth(BFCRouter);
