import React, { useState } from 'react';
import { Route, Switch, Redirect, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _, { lowerCase } from 'lodash';
import loadable from '@loadable/component';
import withBFCAuth from './withBFCAuth';

const Layout = loadable(() => import('../Layout/index'));
const MainWrapper = loadable(() => import('./MainWrapper'));
import ServerMaintenanceModal from '../Layout/ServerMaintenanceModal'
import Account from '../Account';
import PlanList from 'pages/storage/components/PlanList';
import EquipmentRental from 'pages/CTEquipmentRental';
import EquipmentRentalTransactions from 'pages/CTEquipmentRental/equipmentRentalTransactions';
import EquipmentProfile from 'pages/CTEquipmentRental/equipmentProfile';
import IndentDeals from 'pages/CTIndentDeals';

import QuickTransfer from 'pages/storage/components/QuickTransfer';
import StorageTransfer from 'pages/storage/components/StorageTransfers';
import EquipmentPayments from 'pages/CTEquipmentRental/equipmentPayments';
import Facilitator from 'pages/facilitator';
import ReactGA from 'react-ga';
import EarlyPayment from 'pages/CTEarlyPayments';
import AdjustmentFactor from 'pages/CTEarlyPayments/adjustmentFactor';
import SystemConfiguration from 'pages/CTSystemConfigrations';
import SmartBuyerConfiguration from 'pages/CTSmartBuyerConfiguration';
import MinimumDiscount from 'pages/CTEarlyPayments/MinimumDiscount';
import zohoConfiguration from 'pages/configuration/Zoho';
import GPALog from 'pages/configuration/account';
import MUIDemo from 'common/MUIComponents/demo';
import ZohoLogs from 'pages/configuration/Zoho/zohoLog';
import ZohoPaymentLog from 'pages/configuration/Zoho/zohoPaymentLog';
import FarmerOnboarding from 'pages/network/farmerOnboarding';
import useQuery from 'lib/hooks/useQuery';
import NotConfirmedDeliveries from 'pages/delivery/components/NotConfirmedDeliveries';
import { getDecryptedUrl } from 'lib/utils';
import AdvancePaymentsListPage from 'pages/invoice/advancePayments';
// import MissedCallTracker from 'pages/missedCallTracker';
//import BidOffer from 'pages/bidOffer';

// ReactGA.pageview(window.location.pathname + window.location.search)

const Login = loadable(() => import('../Account/Login'));
const NotFound404 = loadable(() => import('../Misc/DefaultPage/404/index'));
const UnderConstruction = loadable(() => import('../Misc/UnderConstruction/index'));
const Signup = loadable(() => import('../Account/Signup'));
const Social = loadable(() => import('pages/socialFeed'));
const Html = loadable(() => import('containers/Html'));
const UserDashboard = loadable(() => import('pages/dashboard'));
const ControlTowerDashboard = loadable(() => import('pages/dashboard/controlTower'));
const UserNetwork = loadable(() => import('pages/network'));
const UserSupport = loadable(() => import('pages/support'));
const CommodityListPage = loadable(() => import('pages/commodity/list'));
const MultipartyAuctionListPage = loadable(() => import('pages/multipartyAuction/list'));
const MultipartyAuctionLiveRoom = loadable(() => import('pages/multiPartyAuctionDetails/multipartyAuctionLiveRoom'));
const CommodityDetailsPage = loadable(() => import('pages/commodityDetails'));
const CommodityPlansDetailsPage = loadable(() => import('pages/commodityPlansDetails'));
const CommodityAuctionDetailsPage = loadable(() => import('pages/commodityAuctionDetails'));
const CommodityCreatePage = loadable(() => import('pages/commodity/create'));
const CommodityProcessingDeal = loadable(() => import('pages/processingDeals/create'));
const CommodityEditProcessingDeal = loadable(() => import('pages/processingDeals/edit'))
const CommodityCreatePlans = loadable(() => import('pages/commodity/createPlan'));
const CommodityCreateAuction = loadable(() => import('pages/commodity/createAuction'));
const CommodityMultiPartyCreateAuction = loadable(() => import('pages/multipartyAuction'));
const CommodityMultiPartyEditAuction = loadable(() => import('pages/multipartyAuction/components/edit'));
const CommodityMultiPartyAuctionDetails = loadable(() => import('pages/multiPartyAuctionDetails'));
const KYCApproval = loadable(() => import('../Account/Profile/KYCApproval'));
const CommodityEditPage = loadable(() => import('pages/commodity/edit'));
const CommodityEditPlan = loadable(() => import('pages/commodity/editPlan'));
const CommodityEditAuction = loadable(() => import('pages/commodity/editAuction'));
const AuctionLiveRoom = loadable(() => import('pages/commodityAuctionDetails/auctionLiveRoom'));
const AuctionLiveRoomDeals = loadable(() => import('pages/commodityAuctionDetails/auctionDeals'));
const MultiPartAuctionLiveRoomDeals = loadable(() => import('pages/multiPartyAuctionDetails/multiPartyAuctionDeals'))
const DeliveryDetails = loadable(() => import('pages/deliveryDetails'));
const TransportRequirementPage = loadable(() => import('pages/transport/list'));
const CommodityDealDetailsPage = loadable(() => import('pages/commodityDealDetails'));
const ProcessingDealDetailsPage = loadable(() => import('pages/processingDealDetails'));
const CommodityDealPage = loadable(() => import('pages/commodityDetails/deals'));
const TransportOverviewPage = loadable(() => import('pages/transportDetails/overview'));
const TransportBalancePositionPage = loadable(() => import('pages/transportDetails/balancePosition'));
const TransportBidsPage = loadable(() => import('pages/transportDetails/bids'));
const TransportDealPage = loadable(() => import('pages/transportDetails/deals'));
const TransportDealOverviewPage = loadable(() => import('pages/transportDealDetails/overview'));
const TRDealInvoiceListPage = loadable(() => import('pages/transportDealDetails/invoice'));
const TRDealPaymentListPage = loadable(() => import('pages/transportDealDetails/payment/list'));
const OngoingDeliveries = loadable(() => import('pages/delivery/components/onGoingDeliveries'));
const InvoiceListPage = loadable(() => import('pages/invoice/list'));
const PaymentListPage = loadable(() => import('pages/payment/list'));
const OverviewPage = loadable(() => import('pages/overview'));
const PaymentDetails = loadable(() => import('pages/payment/details'));
const PaymentDetailsOverview = loadable(() => import('pages/payment/overview'));
const InvoiceForPayments = loadable(() => import('pages/payment/overview/InvoiceForPayments'));
const InvoiceDetails = loadable(() => import('pages/invoice/details'));
const CombinedInvoice = loadable(() => import('pages/cashflow/combinedInvoice'));
const RecordPaymentForm = loadable(() => import('pages/invoice/recordPayments'));
const EnhancedPayments = loadable(() => import('pages/invoice/recordPayments/enhancedPayments'));
const GenerateReport = loadable(() => import('pages/generateReport'));
const BidOffer = loadable(() => import('pages/bidOffer'));
const LinkedDealTracker = loadable(() => import('pages/linkedDealTracker'));
const BuySellTracker = loadable(() => import('pages/CTDataSetup/buySellTracker'));
const CreateDiscountPlan = loadable(() => import('pages/discountPlan/create'));
const EditDiscountPlan = loadable(() => import('pages/discountPlan/edit'));
const FetchDiscountPlan = loadable(() => import('pages/discountPlan/list'));
const DiscountDetails = loadable(() => import('pages/discountPlanDetails'));
const CTSettings = loadable(() => import('pages/CTSettings/index'));
const DeliveryContractForm = loadable(() => import('pages/CTDeliveryContract/create'));
const DeliveryContractEditForm = loadable(() => import('pages/CTDeliveryContract/edit'));
const TransportDealDetails = loadable(() => import('pages/network/transportDeal/transportDealDetails'));
const LinkTransportForm = loadable(() => import('pages/CTDeliveryContract/linkTransport'));
const DeliveryContractDetails = loadable(() => import('pages/CTDeliveryContract/components/DeliveryContractDetails'));
const DriverList = loadable(() => import('pages/network/driver/index'));
const VehicleList = loadable(() => import('pages/network/vehicle/index'));
const EnviraQuotationSetup = loadable(() => import('pages/enviraQuotationSetup'));
const SubscriptionPackages = loadable(() => import('pages/subscription/SubscriptionPackages'));
const Subscription = loadable(() => import('pages/subscription/Subscriptions'));
const SubscriptionDetails = loadable(() => import('pages/subscription/components/SubscriptionDetails'));
const Storage = loadable(() => import('pages/storage'));
const StorageBinDetailsPage = loadable(() => import('pages/storage/components/binDetails'));
const StorageTable = loadable(() => import('pages/storage/components/storageTable'));
const Configuration = loadable(() => import('pages/configuration'));
const AuthorizedSignatory = loadable(() => import('pages/configuration/authorizedSignatory'));
const LoadingUnloading = loadable(() => import('pages/configuration/loadingUnloading'));
const TransportDeal = loadable(() => import('pages/network/transportDeal'));
const CommodityDetailsDataSetup = loadable(() => import('pages/CTDataSetup/commodityDetails'));
const PlatformChargesDataSetup = loadable(() => import('pages/CTDataSetup/platformCharges'));
const PlatformChargeDetailsPopup = loadable(() => import('pages/CTDataSetup/platformCharges/components/PlatformChargeDetailsPopup'));
const DataExtract = loadable(() => import('pages/CTDataSetup/dataExtract'));
const DataExtractNew = loadable(() => import('pages/CTDataSetup/dataExtractNew'));
const ReportPlatformCharges = loadable(() => import('pages/CTDataSetup/reportPlatformCharges'))
const EmissionsSaved = loadable(() => import('pages/CTDataSetup/sustainability'))
const QualityServicesList = loadable(() => import('pages/network/qualityServices/newIndex'));
const MachineRegistration = loadable(() => import('pages/network/qualityServices/components/FieldTestingKit/MachineRegistration'));
const MachineAllotment = loadable(() => import('pages/network/qualityServices/components/FieldTestingKit/MachineAllotment'));
const MachineTestResults = loadable(() => import('pages/network/qualityServices/components/FieldTestingKit/TestResults'));
const QualityServicePage = loadable(() => import('pages/network/qualityServices/create'));
const QualityServiceEditPage = loadable(() => import('pages/network/qualityServices/edit'));
const QualityProfilePage = loadable(() => import('pages/QualityProfile'));
const QualityServiceOverview = loadable(() => import('pages/network/qualityServices/components/details'));
const QualityServiceDocument = loadable(() =>
  import('pages/network/qualityServices/components/details/uploadDocuments'),
);
const Finance = loadable(() => import('pages/finance'));
const FinanceDetails = loadable(() => import('pages/finance/components/FinanceDetails'));
const FinanceUserDetails = loadable(() => import('pages/finance/components/FinanceUserDetails'));
const FinanceDeals = loadable(() => import('pages/finance/components/financeDeals'));
const FinanceInvoices = loadable(() => import('pages/finance/components/financeInvoices'));
const FinanceTable = loadable(() => import('pages/finance/components/financeTable'));
const BankNbfcForm = loadable(() => import('pages/finance/components/create'));
const MarketFinanceInvoices = loadable(() => import('pages/finance/components/MarketFinanceInvoices/list'));
const RawMaterialFinance = loadable(() => import('pages/finance/components/RawMaterialFinance'));
const CreditLimit = loadable(() => import('pages/finance/components/creditLimit'));
const DealFulfilmentView = loadable(() => import('pages/network/dealTracker/dealFulfilment'));
const FuelPriceMaster = loadable(() => import('pages/network/fuelPrice'));
const FuelConfiguration = loadable(() => import('pages/network/fuelConfiguration'));
const FuelManagement = loadable(() => import('pages/network/fuelManagement'));
const TrackerDeviceMaster = loadable(() => import('pages/network/trackerDeviceMaster'));
const LinkedDealView = loadable(() => import('pages/network/dealTracker/linkedDeal'));
const ExtendedProfileList = loadable(() => import('pages/financePartner/index'));
const ExtendedProfileSubmitForm = loadable(() => import('pages/financePartner/submitForm'));
const ExtendedProfileForm = loadable(() => import('pages/financePartner/form'));
const Subscriptions = loadable(() => import('pages/CTSubscriptions'));
const InvoiceConfiguration = loadable(() => import('pages/configuration/invoiceConfiguration'));
const ConfigurationDashBorad = loadable(() => import('pages/configuration/dashborad'));
const CashflowListPage = loadable(() => import('pages/cashflow'));
const ManualInvoice = loadable(() => import('pages/ManualInvoice'));
const ManualInvoiceListPage = loadable(() => import('pages/ManualInvoice/list'));
const FollowPreferences = loadable(() => import('pages/CTMyBioFuelCircle/FollowPreferences'));
const Banner = loadable(() => import('pages/CTMyBioFuelCircle/Banner'));
const Map = loadable(() => import('pages/CTDataSetup/MyMap'));
const PickupMap = loadable(() => import('pages/commodityDetails/PickUpBuy/MapView'))
const ShareSocialFeed = loadable(() => import('pages/socialFeed/components/Feed/sharePost'));
const BfcFeedMainPost = loadable(() => import('pages/socialFeed/components/Feed/bfcFeedMainSite'));
const PlanPassbook = loadable(() => import('pages/subscription/components/PlanPassbook'));
const MySubscription = loadable(() => import('pages/subscription/components/MySubscription'));
const LocationSubscription = loadable(() => import('pages/subscription/components/UserSubscriptionDetails'));
const CreditUtilization = loadable(() => import('pages/finance/components/CreditUtilization'));
const Ledger = loadable(() => import('pages/ledger'));
const TransportRate = loadable(() => import('pages/network/transportRate'));
const PrintableInvoiceGenerator = loadable(() => import('pages/PrintableInvoice'));
const PrintableQuote = loadable(() => import('pages/printableQuote/index'));
const PrintableQuoteOld = loadable(() => import('pages/printableQuote/indexOld'));
const WhatsAppLogin = loadable(() => import('containers/Account/Login/components/WhatsAppLogin'));
const CommodityExplorePage = loadable(() => import('pages/commodityExplorePage'));
const ProcessingDeals = loadable(() => import('pages/processingDeals'));
const FindBuyersDiscovery = loadable(() => import('pages/findBuyersSellersDiscovery'));
const FindSellersDiscovery = loadable(() => import('pages/findBuyersSellersDiscovery'));
const MissedCallTracker = loadable(() => import('pages/missedCallTracker'));
const DeleteMyAccount = loadable(() => import('pages/deleteMyAccount'));
const EquipmentRentalMarket = loadable(() => import('pages/equipmentRental'));
const AddEquipmentForm = loadable(() => import('pages/equipmentRental/CreateEquipment/addEquipment'));
const DetailsScreen = loadable(() => import('pages/equipmentRental/EquipmentDetails/DetailsScreen'));
const PublishEquipment = loadable(() => import('pages/equipmentRental/EquipmentDetails/PublishEquipment'));
const AggregationPlanning = loadable(() => import('pages/network/aggrigationPlanning'));

// When you add separate private route then please add module_name or sub_module_name for that specifit route as done in all the private routes (for private routes only)

const PrivateRoute = ({ component: Component, user, sub_module_name, module_name, location, ...rest }) => {

  let loc = getDecryptedUrl(window.location.href);
  let { is_ct_user, isControlTower, market, isCtDelivery } = useQuery();
  const roles = useSelector((state) => state.rolesAndRights);
  const rolesList = roles.entities || {};
  let routeRecord;
  // Check if module_name is an array
  if (Array.isArray(module_name)) {
    routeRecord = Object.values(rolesList).find((x) => {
      if ((loc.includes('/controltower') || is_ct_user || isControlTower || isCtDelivery)) {
        return module_name.includes("CT_ParentModule")
      } else {
        return module_name.includes(x.module_name) && loc.includes(lowerCase(x.module_name))
      }
    }
    );
  } else {
    // Single module_name case
    routeRecord = Object.values(rolesList).find((x) => x.module_name === module_name || x.sub_module_name === sub_module_name);
  }
  const routeAccess = _.get(routeRecord, 'full_access');

  if (!_.get(user, 'isAuthenticated')) {
    return (
      <Redirect
        to={{
          pathname: '/account/login',
          state: { from: location },
        }}
      />
    );
  }

  const isPathNameIncludes = is_ct_user || isControlTower || window.location.href.includes("controltower") || isCtDelivery
  // if (!user?.profile?.person?.is_control_tower_user && isPathNameIncludes) {
  //   return <>
  //     <NotFound404 />
  //   </>
  // } else if (!routeAccess) {
  //   return <>
  //     <NotFound404 />
  //   </>;
  // }
  return <Route {...rest} render={(props) => <Component user={user} {...props} />} />;
};

//= ======for pages where user shouldn't visit when logged in
const AuthRoute = ({ component: Component, user, ...rest }) => {
  if (_.get(user, 'isAuthenticated')) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} render={(props) => <Component user={user} {...props} />} />;
};

const SharePostRoute = ({ component: Component, user, ...rest }) => {
  return <Route {...rest} render={(props) => <Component user={user} {...props} />} />;
};

const PublicRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const BFCRouter = (props) => {
  const isServerUnderMaintenance = useSelector((state) => state.system.isServerUnderMaintenance);
  const { user } = props;
  const loc = getDecryptedUrl(window.location.href);
  let { market } = useQuery();

  let cls = 'bfc-old-style';
  const pathname = props.history.location.pathname || '';

  if (!pathname.includes('plans') && (pathname.includes('offers') || pathname.includes('requirements'))) {
    cls = 'bfc-new-style';
  }
  return (
    <MainWrapper>
      <main className={cls}>
        <div>
          {(loc.includes('mybiofuelcircle') || loc.includes('/printable-contract') || loc.includes('/printable-quote')) ? null : <Layout user={user} />}

          <div className={(loc.includes('/printable-contract') || loc.includes('/printable-quote')) ? '' : (loc.includes('/social/feed') || loc.includes('/social/saved-posts') || loc.includes('/social/group')) ? 'container__wrap bfc-container__wrap bfc-html container__wrap-socialfeed' : `container__wrap ${cls === 'bfc-old-style' ? 'bfc-container__wrap bfc-html' : ''}`}>
            <Switch>
              <AuthRoute user={props.user} path="/account/login" component={Login} />
              <AuthRoute user={props.user} path="/account/signup" component={Signup} />
              <AuthRoute user={props.user} path="/sso/:id/callback" component={WhatsAppLogin} />
              <SharePostRoute user={props.user} path="/social-feed/post/:id" component={ShareSocialFeed} />
              <SharePostRoute user={props.user} path="/mybiofuelcircle" component={BfcFeedMainPost} />

              <PrivateRoute
                user={props.user}
                path="/social"
                component={Social}
                module_name="myBiofuelCircle"
              />
              <PrivateRoute
                user={props.user}
                path="/account/:id"
                component={Account}
                module_name="Profile"
              />
              <PrivateRoute
                user={props.user}
                path="/account"
                component={Account}
                module_name="Profile"
              />
              <PrivateRoute user={props.user} path="/html" component={Html} />
              <PrivateRoute
                user={props.user}
                exact path="/"
                component={Social}
                module_name="myBiofuelCircle"
              />
              <PrivateRoute
                user={props.user}
                exact path="/dashboard"
                component={UserDashboard}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact path="/dashboard/control-tower"
                component={ControlTowerDashboard}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/:filter(all|user|bids|plans|auction-all|auction-user|auction-bids|quotations)"
                component={CommodityListPage}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/markets-live"
                component={MultipartyAuctionListPage}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/markets-live/:id/liveroom"
                component={MultipartyAuctionLiveRoom}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/create"
                component={CommodityCreatePage}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/processing-deal/create"
                component={CommodityProcessingDeal}
                module_name="Market"
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/processing-deal/edit/:id"
                component={CommodityEditProcessingDeal}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/plans/create"
                component={CommodityCreatePlans}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/auction/create"
                component={CommodityCreateAuction}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/markets-live/create"
                component={CommodityMultiPartyCreateAuction}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/markets-live/edit/:id"
                component={CommodityMultiPartyEditAuction}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/edit/:id"
                component={CommodityEditPage}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/plans/edit/:id"
                component={CommodityEditPlan}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/auction/edit/:id"
                component={CommodityEditAuction}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/auction/:id/liveroom"
                component={AuctionLiveRoom}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/markets-live/:id/liveroom-deals"
                component={MultiPartAuctionLiveRoomDeals}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/commodity/:type(requirements|offers)/auction/:id/liveroom-deals"
                component={AuctionLiveRoomDeals}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                // exact
                path="/commodity/:type(requirements|offers)/auction/:id"
                component={CommodityAuctionDetailsPage}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                // exact
                path="/commodity/:type(requirements|offers)/:id"
                component={CommodityDetailsPage}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                // exact
                path="/commodity/markets-live/:id"
                component={CommodityMultiPartyAuctionDetails}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                // exact
                path="/commodity/:type(requirements|offers)/plans/:id"
                component={CommodityPlansDetailsPage}
                module_name="Market"
              />


              <PrivateRoute
                user={props.user}
                exact path="/commodity/deals"
                component={CommodityDealPage}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                path="/commodity/deals/:id"
                component={CommodityDealDetailsPage}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                path="/network/processing-deal/:id"
                component={ProcessingDealDetailsPage}
                module_name="Network"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/deals"
                component={CommodityDealPage}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                path="/control-tower/deals/:id"
                component={CommodityDealDetailsPage}
                module_name="CT_ParentModule"
              />

              <PrivateRoute
                user={props.user}
                path="/deliveries/:id"
                component={DeliveryDetails}
                module_name={["CT_ParentModule", "Market", "Network"]}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport"
                component={TransportRequirementPage}
                sub_module_name="Transport"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport/requirements/create"
                component={CommodityCreatePage}
                sub_module_name="Transport"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport/requirements/:id/overview"
                component={TransportOverviewPage}
                sub_module_name="Transport"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport/requirements/:id/balance-position"
                component={TransportBalancePositionPage}
                sub_module_name="Transport"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport/requirements/:id/bids"
                component={TransportBidsPage}
                sub_module_name="Transport"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport/requirements/:id/deals"
                component={TransportDealPage}
                sub_module_name="Transport"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport/requirements/100/deals/:id/overview"
                component={TransportDealOverviewPage}
                sub_module_name="Transport"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport/requirements/100/deals/:id/tr-invoices"
                component={TRDealInvoiceListPage}
                sub_module_name="Transport"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/transport/requirements/100/deals/:id/tr-payment"
                component={TRDealPaymentListPage}
                sub_module_name="Transport"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/:place(market|network|controltower)/deliveries/:type(upcoming|ongoing|completed|missed)"
                component={OngoingDeliveries}
                module_name={["CT_ParentModule", "Market", "Network"]}

              />
              <PrivateRoute
                user={props.user}
                exact
                path="/:place(market|network|controltower)/deliveries/:type(not-confirmed)"
                component={NotConfirmedDeliveries}
                module_name={["CT_ParentModule", "Market", "Network"]}

              />
              <PrivateRoute
                user={props.user}
                exact
                path="/:place(market|network|controltower)/cashflow"
                component={CashflowListPage}
                module_name={["CT_ParentModule", "Market", "Network"]}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/:place(market|network|controltower)/invoices"
                component={InvoiceListPage}
                module_name={["CT_ParentModule", "Market", "Network"]}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/:place(market|network|controltower)/advance/payments"
                component={AdvancePaymentsListPage}
                module_name={["CT_ParentModule", "Market", "Network"]}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/:place(market|network|controltower)/payments"
                component={PaymentListPage}
                module_name={["CT_ParentModule", "Market", "Network"]}
              />

              <PrivateRoute
                user={props.user}
                exact path="/overview"
                component={OverviewPage}
                module_name={["CT_ParentModule", "Market", "Network"]}
              />
              <PrivateRoute user={props.user}
                exact
                path="/invoices/record-payment"
                component={RecordPaymentForm}
                module_name="Market"
              />
              <PrivateRoute user={props.user}
                exact
                path="/payouts"
                component={EnhancedPayments}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/invoices/:id"
                component={InvoiceDetails}
                module_name={["CT_ParentModule", "Market", "Network"]}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/market/invoices/:id"
                component={InvoiceDetails}
                module_name={"Market"}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/network/invoices/:id"
                component={InvoiceDetails}
                module_name={"Network"}
              />
              <PrivateRoute user={props.user} exact path="/invoices/consigment-note"
                component={InvoiceDetails}
                module_name={"Market"}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/invoices/:id/:manualInvoice"
                component={InvoiceDetails}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact path="/combined-invoices"
                component={CombinedInvoice}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact path="/payment/:id"
                component={PaymentDetails}
                module_name={["CT_ParentModule", "Market", "Network"]}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/payment/:id/overview"
                component={PaymentDetailsOverview}
                module_name={["CT_ParentModule", "Market", "Network"]}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/market/payment/:id/overview"
                component={PaymentDetailsOverview}
                module_name={"Market"}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/network/payment/:id/overview"
                component={PaymentDetailsOverview}
                module_name={"Network"}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/payment/:id/invoice"
                component={InvoiceForPayments}
                module_name={["CT_ParentModule", "Market", "Network"]}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/market/payment/:id/invoice"
                component={InvoiceForPayments}
                module_name={"Market"}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/network/payment/:id/invoice"
                component={InvoiceForPayments}
                module_name={"Network"}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/report"
                component={GenerateReport}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/bid-offer-tracker"
                component={BidOffer}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/buy-sell-tracker"
                component={BuySellTracker}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/linked-deal-tracker"
                component={LinkedDealTracker}
                module_name="CT_ParentModule"
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/create-discount"
                component={CreateDiscountPlan}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/edit-discount/:id"
                component={EditDiscountPlan}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/discount-plans"
                component={FetchDiscountPlan}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/discount/:id/schemes"
                component={DiscountDetails}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/discount/:id/claims"
                component={DiscountDetails}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/settings/change-banner"
                component={CTSettings}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact path="/controltower/delivery-contract"
                component={TransportDeal}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/controltower/delivery-contract/create"
                component={DeliveryContractForm}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/controltower/delivery-contract/:id/edit"
                component={DeliveryContractEditForm}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/controltower/delivery-contract/link-transport"
                component={LinkTransportForm}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                path="/controltower/delivery-contract/:id"
                component={DeliveryContractDetails}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/controltower/manual-invoice"
                component={ManualInvoiceListPage}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/controltower/manual-invoice/create"
                component={ManualInvoice}
                module_name="CT_ParentModule"
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/controltower/manual-invoice/edit/:id"
                component={ManualInvoice}
                module_name="CT_ParentModule"
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/ledger"
                component={Ledger}
                module_name="CT_ParentModule"
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/market/ledger"
                component={Ledger}
                module_name="Market"
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/network/ledger"
                component={Ledger}
                module_name="Network"
              />
              <Route exact path="/commodity" render={() => <Redirect to={'/commodity/requirements/all'} />} />
              <Route exact path="/commodity/:type" render={() => <Redirect to={'/commodity/requirements/all'} />} />
              <Route
                exact
                path="/commodity/:type/:filter"
                render={() => <Redirect to={'/commodity/requirements/all'} />}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/kycapproval/:type(individual|business)"
                component={KYCApproval}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                path="/network/driver"
                component={DriverList}
                module_name="Network"
              />
              <PrivateRoute
                user={props.user}
                path="/network/vehicle"
                component={VehicleList}
                module_name={["CT_ParentModule", "Network"]}
              />
              <PrivateRoute
                user={props.user}
                path="/controlTower/enviraSetup"
                component={EnviraQuotationSetup}
                // module_name={["CT_ParentModule", "Network"]}
              />
              <PrivateRoute
                user={props.user}
                path="/market/quality-services/:type(my-reports|shared-with-me)/:id"
                component={QualityServiceOverview}
                module_name="Market"
              />
              <PrivateRoute
                exact user={props.user}
                path="/market/quality-services/:type(my-reports|shared-with-me)"
                component={QualityServicesList}
                module_name="Market"
              />
              {/* <PrivateRoute exact user={props.user} path="/market/quality-services/:type(my-reports|shared-with-me)" component={QualityServicesList} /> */}
              <PrivateRoute
                exact user={props.user}
                path="/network/quality-services"
                component={QualityServicesList}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                exact user={props.user}
                path="/network/quality-services"
                component={QualityServicesList}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                exact user={props.user}
                path="/network/quality-services/machine-registration"
                component={MachineRegistration}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                exact user={props.user}
                path="/network/quality-services/machine-allotment"
                component={MachineAllotment}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                exact user={props.user}
                path="/network/quality-services/machine-testing-results"
                component={MachineTestResults}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                exact
                user={props.user}
                path="/network/create-qualityServices"
                component={QualityServicePage}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                exact
                user={props.user}
                path="/market/quality-services/quality-profile/:entity_id/:affilateEnum/:addressId"
                component={QualityProfilePage}
                module_name="Market"
              />
              <PrivateRoute
                exact
                user={props.user}
                path="/network/quality-services/:id/edit"
                component={QualityServiceEditPage}
                module_name="Network"
              />

              <PrivateRoute
                exact
                user={props.user}
                path="/market/quality-services/:id/edit"
                component={QualityServiceEditPage}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                path="/network/quality-services/:id"
                component={QualityServiceOverview}
                module_name="Network"
              />
              <PrivateRoute
                user={props.user}
                path="/market/quality-services/:id"
                component={QualityServiceOverview}
                module_name="Market"
              />

              <PrivateRoute
                user={props.user}
                exact path="/network"
                component={UserNetwork}
                module_name="Network"
              />
              <PrivateRoute
                user={props.user}
                exact path="/support"
                component={UserSupport}
                module_name="Support"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/subscription-packages"
                component={SubscriptionPackages}
                module_name="Profile"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/subscription"
                component={Subscription}
                module_name="Profile"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/subscription/:name/:id"
                component={SubscriptionDetails}
                module_name="Profile"
              />
              <PrivateRoute
                user={props.user}
                path="/storages"
                component={Storage}
                module_name={market ? "Market" : "CT_ParentModule"}
              />
                <PrivateRoute
                user={props.user}
                path="/equipmentRental/yourEquipments"
                component={EquipmentRentalMarket}
                module_name= "Network"
              />
                <PrivateRoute
                user={props.user}
                path="/equipmentRental/addEquipmentForm"
                component={AddEquipmentForm}
                module_name= "Network"
              />
                <PrivateRoute
                user={props.user}
                path="/equipmentRental/DeatailsScreen/:id(\d*)"
                component={DetailsScreen}
                module_name= "Network"
              />
               <PrivateRoute
                user={props.user}
                path="/equipmentRental/PublishEquipment/:id(\d*)"
                component={PublishEquipment}
                module_name= "Network"
              />
              <PrivateRoute
                user={props.user}
                exact path="/storage/bindetails"
                component={StorageBinDetailsPage}
                module_name={["CT_ParentModule", "Market"]}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/storage/bindetails/:location/:level"
                component={StorageTable}
                module_name={["CT_ParentModule", "Market"]}
              />
              <PrivateRoute
                user={props.user}
                exact path="/storage/plan"
                component={PlanList}
                module_name={["CT_ParentModule", "Market"]}
              />
              <PrivateRoute
                user={props.user}
                exact path="/configuration"
                component={ConfigurationDashBorad}
                module_name="Configuration_Parent"
              />
              <PrivateRoute
                user={props.user}
                path="/configuration/:type(preferred|blocked)"
                component={Configuration}
                module_name="Configuration_Parent"
              />
              <PrivateRoute
                user={props.user}
                path="/configuration/authorized-signatory"
                component={AuthorizedSignatory}
                module_name="Configuration_Parent"
              />
              <PrivateRoute
                user={props.user}
                path="/configuration/loading-unloading-charges"
                component={LoadingUnloading}
                module_name="Configuration_Parent"
              />
              <PrivateRoute
                user={props.user}
                path="/configuration/invoice-configuration"
                component={InvoiceConfiguration}
                module_name="Configuration_Parent"
              />
              <PrivateRoute
                exact user={props.user}
                path="/network/transport-deal"
                component={TransportDeal}
                module_name="Network"
              />
              <PrivateRoute
                user={props.user}
                path="/network/transport-deal/:id"
                module_name="Network"
              >
                <TransportDealDetails backUrl="/network/transport-deal" />
              </PrivateRoute>

              <PrivateRoute
                user={props.user}
                path="/controltower/data-setup/commodity-details"
                component={CommodityDetailsDataSetup}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                path="/controltower/data-setup/platform-charges"
                component={PlatformChargesDataSetup}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                path="/control-tower/data-setup/platform-charge/details"
                component={PlatformChargeDetailsPopup}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                path="/control-tower/report/map-view"
                component={Map}
                module_name="CT_ParentModule"
              />

              <PrivateRoute
                user={props.user}
                path="/market/pickup/:id/map-view"
                component={PickupMap}
                module_name="Market"
              />

              <PrivateRoute
                user={props.user}
                exact path="/extended_profile/create"
                component={ExtendedProfileForm}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/extended_profile/edit/:id"
                component={ExtendedProfileForm}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/extended_profile/submit_form/:id"
                component={ExtendedProfileSubmitForm}
                module_name="CT_ParentModule"
              />

              <PrivateRoute
                user={props.user}
                exact path="/finance"
                component={Finance}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact path="/finance-details/:id"
                component={FinanceDetails}
                sub_module_name="Finance"
              />
              <PrivateRoute
                user={props.user}
                exact path="/finance/:id/overview"
                component={FinanceUserDetails}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/finance/:id/deals"
                component={FinanceDeals}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/finance/:id/invoices"
                component={FinanceInvoices}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/finance/invoices/all"
                component={FinanceInvoices}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact path="/finance/bank-nbfc-info/create"
                component={BankNbfcForm}
                sub_module_name="Finance"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/finance/invoices"
                component={MarketFinanceInvoices}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/finance/deals"
                component={RawMaterialFinance}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                path="/finance/credit-limit"
                component={CreditLimit}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                path="/control-tower/fuel-price"
                component={FuelPriceMaster}
                module_name="Network"
              />
               <PrivateRoute
                user={props.user}
                path="/control-tower/fuel-configuration"
                component={FuelConfiguration}
                module_name="Network"
              />
               <PrivateRoute
                user={props.user}
                path="/control-tower/fuel-management"
                component={FuelManagement}
                module_name="Network"
              />
              <PrivateRoute
                user={props.user}
                path="/equipmentRental/trackerDeviceMaster"
                component={TrackerDeviceMaster}
                module_name="Network"
              />
              <PrivateRoute
                user={props.user}
                path="/network/deal-fulfilment-view"
                component={DealFulfilmentView}
                module_name="Network"
              />
              <PrivateRoute
                user={props.user}
                path="/network/linked-deal-view"
                component={LinkedDealView}
                module_name="Network"
              />
              <PrivateRoute
                user={props.user}
                exact path="/finance/credit-utilization"
                component={CreditUtilization}
                module_name="CT_ParentModule"
              />

              <PrivateRoute
                user={props.user}
                path="/controltower/data-setup/data-extract"
                component={DataExtract}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                path="/controltower/data-setup/new-data-extract"
                component={DataExtractNew}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                path="/controltower/data-setup/report-platform-charges"
                component={ReportPlatformCharges}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                path="/controltower/data-setup/emission-saved"
                component={EmissionsSaved}
                module_name="CT_ParentModule"
              />

              <PrivateRoute
                user={props.user}
                path="/extended_profile"
                component={ExtendedProfileList}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                exact
                user={props.user}
                path="/connect/quality-services/quality-profile/:entity_id/:affilateEnum/:addressId"
                // path="/commodity/:type(requirements|offers)/:id"
                component={QualityProfilePage}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                path="/control-tower/subscriptions"
                component={Subscriptions}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                path="/control-tower/follow-preferences"
                component={FollowPreferences}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                path="/follow-preferences"
                component={FollowPreferences}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                path="/control-tower/banner"
                component={Banner}
                module_name="CT_ParentModule"
              />

              <Route path="/under-construction">
                <UnderConstruction />
              </Route>
              <PrivateRoute
                user={props.user}
                path="/subscription/plan-passbook/:addressId/:personComapnyAffiliateId/:personComapnyAffiliateEnum"
                component={PlanPassbook}
                module_name="Profile"
              />
              <PrivateRoute
                user={props.user}
                path="/subscription/mySubscription"
                component={MySubscription}
                module_name="Profile"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/location/subscription/:addressId/:personComapnyAffiliateId/:personComapnyAffiliateEnum"
                component={LocationSubscription}
                module_name="Profile"
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/transport-rate"
                component={TransportRate}
                module_name="CT_ParentModule"
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/equipment-rental"
                component={EquipmentRental}
                module_name="CT_ParentModule"
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/equipmentRental/mapTrackers"
                component={EquipmentRental}
                module_name="CT_ParentModule"
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/equipment-rental-transactions"
                component={EquipmentRentalTransactions}
                module_name="CT_ParentModule"
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/equipment-rental/equipment-details/:id"
                component={EquipmentProfile}
                module_name="CT_ParentModule"
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/indent-deals"
                component={IndentDeals}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/seller-indent-deals"
                component={IndentDeals}
                module_name="CT_ParentModule"
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/equipment-rental/payments"
                component={EquipmentPayments}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/market/storage/transfers"
                component={StorageTransfer}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/market/storage/create-quick-transfer"
                component={QuickTransfer}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/facilitator"
                component={Facilitator}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/transactions"
                component={Facilitator}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/network/facilitator"
                component={Facilitator}
                module_name="Network"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/network/processing-deals"
                component={ProcessingDeals}
                module_name="Network"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/find-buyers-discovery/:findtype(buyers|sellers)"
                component={FindBuyersDiscovery}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/find-buyers-discovery/:findtype(buyers|sellers)"
                component={FindBuyersDiscovery}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/find-sellers-discovery/:findtype(buyers|sellers)"
                component={FindSellersDiscovery}
              />
               <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/find-sellers-discovery/:findtype(buyers|sellers)"
                component={FindSellersDiscovery}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/printable-contract"
                component={PrintableInvoiceGenerator}
                module_name="Market"
              />

              <PublicRoute
                user={props.user}
                exact
                path="/printable-quote/:id"
                component={PrintableQuote}
              />
              <PublicRoute
                user={props.user}
                exact
                path="/printable-quote-old/:id"
                component={PrintableQuoteOld}
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/early-payment"
                component={EarlyPayment}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/adjustment-factor"
                component={AdjustmentFactor}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/minimum-discount"
                component={MinimumDiscount}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/system-configuration"
                component={SystemConfiguration}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/smart-buyer-configuration"
                component={SmartBuyerConfiguration}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/smart-seller-configuration"
                component={SmartBuyerConfiguration}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/configuration/zoho-configuration"
                component={zohoConfiguration}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/configuration/zoho-logs"
                component={ZohoLogs}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/configuration/zoho_payment-log"
                component={ZohoPaymentLog}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/configuration/account/user"
                component={GPALog}
                module_name="Configuration_Parent"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/configuration/account/all"
                component={GPALog}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/mui-components-demo"
                component={MUIDemo}
                module_name="CT_ParentModule"
              />

              <PrivateRoute
                user={props.user}
                exact
                path="/commodity-explore/:type(requirements|offers)"
                component={CommodityExplorePage}
                module_name="Market"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/network/farmer-onboarding"
                component={FarmerOnboarding}
                module_name="CT_ParentModule"
              />
              <PrivateRoute
                user={props.user}
                exact
                path="/control-tower/missed-call-tracker"
                component={MissedCallTracker}
                module_name="CT_ParentModule"
              />
              <PrivateRoute user={props.user} exact path="/accountDelete/:id/delete" component={DeleteMyAccount} />
              <PrivateRoute user={props.user} exact path="/network/aggregation-planning" component={AggregationPlanning} />
              <Route path="/">
                <NotFound404 />
              </Route>
            </Switch>
          </div>
          {isServerUnderMaintenance && (
            <ServerMaintenanceModal
              title="We're making things better for you!"
              open={isServerUnderMaintenance}
              color="primary"
              header
              btn="Primary"
            />)
          }
        </div>
      </main>
    </MainWrapper>
  );
};

export default withBFCAuth(BFCRouter);
